import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {Container, Row, Col} from 'react-bootstrap'
import {SectionWrap} from './section.style'
import SwiperSlider from '../../../../components/ui/swiper'
import ClientLogo from '../../../../components/ui/client-logo'
import SectionTitle from '../../../../components/ui/section-title'
import BrandImage1 from '../../../../data/images/partners/ambank-logo.jpg'
import BrandImage1Hover from '../../../../data/images/partners/ambank-logo-hover.png'
import BrandImage2 from '../../../../data/images/partners/aceatt-logo.jpg'
import BrandImage2Hover from '../../../../data/images/partners/aceatt-logo-hover.png'
import BrandImage3 from '../../../../data/images/partners/firium-logo.jpg'
import BrandImage3Hover from '../../../../data/images/partners/firium-logo-hover.png'
import BrandImage4 from '../../../../data/images/partners/allianz-logo.jpg'
import BrandImage4Hover from '../../../../data/images/partners/allianz-logo-hover.png'
import BrandImage5 from '../../../../data/images/partners/ocean-bride-logo.png'
import BrandImage5Hover from '../../../../data/images/partners/ocean-bridge-hover.png'
import BrandImage6 from '../../../../data/images/partners/mesinniaga-logo.jpg'
import BrandImage6Hover from '../../../../data/images/partners/mesinniaga-hover.jpg'
import BrandImage7 from '../../../../data/images/partners/customecodes-logo.jpg'
import BrandImage7Hover from '../../../../data/images/partners/customcodes-logo-hover.jpeg'
import BrandImage8 from '../../../../data/images/partners/cimb-logo.jpg'
import BrandImage8Hover from '../../../../data/images/partners/CIMB-Logo-hover.png'
import BrandImage9 from '../../../../data/images/partners/great-eastern-logo.jpg'
import BrandImage9Hover from '../../../../data/images/partners/great-eastern-hover.jpg'
import BrandImage10 from '../../../../data/images/partners/rhb-bank-logo.jpg'
import BrandImage10Hover from '../../../../data/images/partners/rhb-bank-hover.jpg'

const Section = ({slider, sliderStyle}) => {
    const [sliderImg] = useState([
        {
            img: BrandImage1,
            hoverImg: BrandImage1Hover
        },
        {
            img: BrandImage2,
            hoverImg: BrandImage2Hover
        },
        {
            img: BrandImage3,
            hoverImg: BrandImage3Hover
        },
        {
            img: BrandImage4,
            hoverImg: BrandImage4Hover
        },
        {
            img: BrandImage5,
            hoverImg: BrandImage5Hover
        },
        {
            img: BrandImage6,
            hoverImg: BrandImage6Hover
        },
        {
            img: BrandImage7,
            hoverImg: BrandImage7Hover
        },
        {
            img: BrandImage8,
            hoverImg: BrandImage8Hover
        },
        {
            img: BrandImage9,
            hoverImg: BrandImage9Hover
        },
        {
            img: BrandImage10,
            hoverImg: BrandImage10Hover
        }
    ])
    return (
        <SectionWrap>
            <Container>
            <Row>
                    <Col lg={12}>
                        <SectionTitle
                            subtitle="Our Happy Clients/Partners We Work With"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <SwiperSlider settings={slider} {...sliderStyle}>
                            {sliderImg.map((slide, i) => {
                                return(
                                    <div key={i} className="item">
                                        <ClientLogo brandImage={slide.img} hoverImage={slide.hoverImg}/>
                                    </div>
                                )
                            })}
                        </SwiperSlider>
                    </Col>
                </Row>
            </Container>
        </SectionWrap>
    )
}

Section.propTypes = {
    bgcolor: PropTypes.string,
    slider: PropTypes.object
}

Section.defaultProps = {
    bgcolor: '#F8F8F8',
    slider: {
        slidesPerView: 6,
        loop: false,
        speed: 1000,
        spaceBetween: 30,
        autoplay: {
            delay: 2500
        },
        breakpoints: {
            320: {
                slidesPerView : 2
            },
            575:{
                slidesPerView : 3
            },
            767:{
                slidesPerView : 4
            },
            991:{
                slidesPerView : 5
            },            
            1499:{
                slidesPerView : 6
            }
        }
    },
    sliderStyle: {
        align: 'center'
    }
}


export default Section;